import React from 'react';
import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";

import img1 from "../../images/image/Dhanishtha Mukhiya.jpg";
import img2 from "../../images/image/Abhas Chowdhary.jpg";
import img3 from "../../images/image/Komal R.jpg";
import img4 from "../../images/image/Saumya Anand Dubey.jpg";


function TeamCard({ img, name, title }) {
  return (
    <Card className="gap-4 overflow-hidden" shadow={false} style={{ width: '250px', height: 'auto' }}>
    <CardBody className="text-center ">
    
        <div class="pr-3">
            <img class="h-32 w-32 rounded-full object-cover" src={img}   alt={name} />
        
        </div>
  
      <Typography variant="h5" color="blue-gray" className="font-medium text-xl mb-2" style={{ fontFamily: 'TheSeasons' }}>
        {name}
      </Typography>
      <Typography
        color="blue-gray"
        className="text-base font-semibold text-gray-600 mb-4" style={{ fontFamily: 'TheSeasons' }}
      >
        {title}
      </Typography>
    </CardBody>
  </Card>
  
  );
}

const members = [
  {
    img: img1,
    name: "Dhanishtha Mukhiya",
    title: "Managing Partner",
  },
  {
    img: img2,
    name: "Abhas Chowdhary",
    title: "Senior Partner & Head of Litigation",
  },
  {
    img: img3,
    name: "Komal R.",
    title: "Senior Partner & Head of Corporate",
  },
  {
    img: img4,
    name: "Saumya Anand Dubey",
    title: "Senior Partner & Head of IPR",
  }
];

const TeamSection12 = () => {
  return (
    <section className="py-8 px-8 lg:py-8 " style={{ fontFamily: 'TheSeasons' }}>
      <div className="container mx-auto" >
        <div className="mb-16 text-center lg:mb-16" >
          <Typography
            variant="h6"
            color="blue-gray"
            className="text-4xl mb-4 " style={{ fontFamily: 'TheSeasons' }}
          >
            About Us
          </Typography>
          <Typography
            variant="lead"
            className="mx-auto w-full !text-gray-500 max-w-4xl text-justify mb-4"  style={{ fontFamily: 'TheSeasons' }}
          >
            Legal Inkwell is a service-based, client-centered law firm. We pride ourselves on providing strategic and result-oriented solutions to our broad spectrum of clientele. 
          </Typography>
          <Typography
            variant="lead"
            className="mx-auto w-full !text-gray-500 max-w-4xl text-justify mb-4" style={{ fontFamily: 'TheSeasons' }}
          >
            Our colleagues are experts in their specific areas and are enthusiastic about adapting to the rapidly changing world. We have been able to solve complex corporate issues to small civil matters. The firm deals in various avenues of law with utmost professionalism and highly trained partners and colleagues who deal proficiently in their niche. We believe in cultural depth, entrustment, and its amalgamation with modern ethics.
          </Typography>
          <Typography
            variant="lead"
            className="mx-auto w-full !text-gray-500 max-w-4xl text-justify mb-4" style={{ fontFamily: 'TheSeasons' }}
          >
            We believe in maintaining transparency and constant updates to our clients, thereby resulting in efficiency and client satisfaction. Our focus is pinpointed on our clients' needs and simplifying complex legal solutions for them.
          </Typography>
        </div>
        <Typography
          variant="h1"
          color="blue-gray"
          className="my-2 !text-2xl lg:!text-4xl text-center mb-16" style={{ fontFamily: 'TheSeasons' }}
        >
          Behind the Success: Our Dedicated Team
        </Typography>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4" >
          {members.map((props, key) => (
            <TeamCard key={key} {...props} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default TeamSection12;
