import React from "react";
import img from "../images/image/7.1.jpg";
import Back from "../common/Back";
import img1 from "../images/image/Dhanishtha Mukhiya.jpg";
import img2 from "../images/image/Abhas Chowdhary.jpg";
import img3 from "../images/image/Komal R.jpg";
import img4 from "../images/image/Saumya Anand Dubey.jpg";
import {

  Typography,
} from "@material-tailwind/react";
// import { Button } from "@material-tailwind/react";
const CoreTeam = () => {
  return (
    <div>
      <section className=' text-4xl mb '>
        <Back name='Core Team' title='' cover={img} />
        </section>
        <section className="py-16 px-4" style={{ fontFamily: 'TheSeasons' }}>
          {/* added gap-6 in div container */}
          <div className="mx-auto container grid place-items-center grid-cols-1 md:grid-cols-2 gap-6">
            {/* added md breakpoints for image h/w  */}
            <img
              src={img1}
              alt="pink blazer"
              className="md:h-[38rem] h-[41rem] md:w-[32rem] overflow-hidden object-cover"
            />
            <div>
              <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
                Dhanishtha Mukhiya
              </Typography>
              <Typography variant="h5" className=" text-gray-600">
                Managing Partner
              </Typography>

              <Typography className="!mt-4 text-base  leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
                Dhanishtha Mukhiya is the Managing Partner of the firm. She is a
                practicing advocate since July, 2017 and is registered member of
                Bar Council of Rajasthan. Dhanishtha has appeared before Hon’ble
                Supreme Court of India, Hon’ble High Court of Rajasthan, Jaipur
                and Jodhpur Bench, Learned District and Session Courts of New
                Delhi and Jaipur, High Court of Delhi, Debt Recovery Tribunal,
                Jaipur, District Consumer Forum, Jaipur, State Consumer
                Commission, Waqf Tribunal, National Company Law Tribunal, Jaipur,
                Family Court, Jaipur and various other forums and authorities. She
                has been litigating since the start of her legal career.
              </Typography>
              <Typography className="!mt-4 text-base   leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
                Dhanishtha graduated with first division from Amity Law School,
                Amity University, Uttar Pradesh with a five-year degree in
                Bachelor of Arts and Bachelor of Law along with honors in Business
                Laws. Dhanishtha has dedicatedly completed her internships under
                fire brand lawyers like Senior Advocate Salman Khurshid, Senior
                Advocate P.S. Narsimha in the Supreme Court of India. She also learned
                the basics of trial by interning in various trial courts of
                Udaipur, Rajasthan and New Delhi.
              </Typography>
              <Typography className="!mt-4 text-base   leading-[27px] !text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
                Dhanishtha has worked under prominent lawyers of Jaipur City like
                Senior Advocate Sudhanshu Kasliwal, Advocate Suruchi Kasliwal and
                Advocate Ram Naresh Vijay. During her time in these offices, she
                handled cases for esteemed organizations like Indian Oil
                Corporation Limited, State Bank of India, East India Hotels, Oil
                and Natural Gas Corporation Limited, Bank of Baroda, ICICI, AU
                Small Finance Bank, Union Bank of India, etc.
              </Typography>
              {/* <div className="mb-4 flex w-full items-center gap-3 md:w-1/2 ">
            <Button color="gray" className="w-52">
              Add to Cart
            </Button>
          </div> */}
            </div>
          </div>
        </section>
        <section className="py-16 px-4 sm:px-8">
          <div className="mx-auto max-w-screen-xl grid gap-8 md:grid-cols-2 items-center">
            <div>
              <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
                Abhas Chowdhary
              </Typography>
              <Typography variant="h5" className=" text-gray-600">
                Senior Partner & Head of Litigation
              </Typography>

              <Typography className="mt-4 text-base   leading-7 text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
                Abhas Chowdhary is a Senior Partner and Head of Litigation of the
                firm. He has been practicing law since last seven years. Abhas has
                profound knowledge of law especially in the area of corporate and
                commercial laws. He has a great grasp on civil and constitutional
                matters along with service matters. He has been appearing before
                Hon’ble Supreme Court of India, Hon’ble High Court of Rajasthan,
                Jaipur and Jodhpur Bench, Learned District and Session Courts of
                Jaipur, District Consumer Forums, Jaipur, State Consumer
                Commission, Jaipur, Debt Recovery Tribunal, Jaipur, National
                Company Law Tribunal, Jaipur and various other forums and
                authorities.
              </Typography>
              <Typography className="mt-4 text-base   leading-7 text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
                Abhas has completed his Bachelor of Commerce from University of
                Pune, Masters in Business Administration from Sikkim Manipal
                University and his Bachelor of Law from University of Rajasthan.
              </Typography>
              <Typography className="mt-4 text-base   leading-7 text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
                He has extensive litigation experience from working under highly
                acclaimed and experienced senior lawyers like Senior Advocate Mr.
                Sudhanshu Kasliwal, Advocate Suruchi Kasliwal, Advocate Ram Naresh
                Vijay. He has handled various cases of esteemed Public Sector
                Undertakings like Indian Oil Corporation Limited, Oil and Natural
                Gas Corporation Limited and Banks of great stature like State Bank
                of India, ICICI etc.
              </Typography>
            </div>
            <img
              src={img2}
              alt="pink blazer"
              className="h-auto md:h-[44rem] w-full md:w-[32rem] object-cover"
            />
          </div>
        </section>

        <section className="py-16 px-4 sm:px-8">
          <div className="mx-auto max-w-screen-xl grid gap-8 md:grid-cols-2 items-center">
            <img
              src={img3}
              alt="pink blazer"
              className="h-auto md:h-[32rem] w-full md:w-[32rem] object-cover"
            />
            <div>
              <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
                Komal R.
              </Typography>
              <Typography variant="h5" className=" text-gray-600">
                Senior Partner & Head of Corporate Law
              </Typography>

              <Typography className="mt-4 text-base   leading-7 text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
                Komal is the Senior Partner & Head of Corporate Law of
                the Firm. She has graduated from the University of Rajasthan. She
                is a practicing corporate and business lawyer having Master’s
                Degree in Business Law with an experience of over 7 years in the
                areas of banking, finance, corporate disputes, insolvency,
                corporate legal due-diligence, regulatory compliances, financial
                crimes, real estate laws, and other routine consultations on all
                business, commercial and financial laws. She is certified in
                Contract Management Laws with expertise in domestic and cross
                border business arrangements. She is also holding certification in
                Foreign Exchange Laws and Financial Crimes having exposure of RBI
                Regulations and compliances on FEMA and PML.
              </Typography>
              <Typography className="mt-4 text-base   leading-7 text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
                She has also actively worked for the safety and protection of
                women at the workplace and endorses safety and equal opportunity
                for women professionals.
              </Typography>
            </div>
          </div>
        </section>

        <section className="py-16 px-4 sm:px-8">
          <div className="mx-auto max-w-screen-xl grid gap-8 md:grid-cols-2 items-center">
            <div>
              <Typography className="mb-4" variant="h3" style={{ fontFamily: 'TheSeasons' }}>
                Saumya Anand Dubey
              </Typography>
              <Typography variant="h5" className=" text-gray-600">
                Senior Partner & Head of Intellectual Property Rights
              </Typography>

              <Typography className="mt-4 text-base   leading-7 text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
                Saumya Anand Dubey is the Senior Partner and Head of IPR in the
                firm. She is a practicing advocate since 2017 and is registered
                member of Bar Council of Uttar Pradesh. Saumya has appeared before
                Hon’ble Supreme Court of India, Hon’ble High Court of Delhi,
                Allahabad High Court, Lucknow High Court, Learned District and
                Session Courts of New Delhi and Prayagraj, High Court of Delhi,
                Debt Recovery Tribunal, New Delhi, Allahabad and Debt Recovery
                Appellate Tribunal, New Delhi, National Dispute Redressal
                Commission, State Consumer Commission, National Company Law
                Tribunal, New Delhi, Family Courts, New Delhi and various other
                forums and authorities. She has been litigating since the start of
                her legal career.
              </Typography>
              <Typography className="mt-4 text-base   leading-7 text-gray-500" style={{ textAlign: 'justify', fontFamily: 'TheSeasons', fontSize: '1.2rem' }}>
                Saumya graduated with first division from Amity Law School, Amity
                University, Uttar Pradesh with a five-year degree in Bachelor of
                Arts and Bachelor of Law along with honors in Intellectual
                Property Rights. Saumya has dedicatedly completed her internships
                under fire brand lawyers like Senior Advocate Salman Khurshid in
                Supreme Court of India. She also learned the basics of trial by
                interning in various trial courts of Prayagraj and New Delhi.
              </Typography>
            </div>
            <img
              src={img4}
              alt="pink blazer"
              className="h-auto md:h-[43rem] w-full md:w-[32rem] object-cover"
            />
          </div>
        </section>
    </div>
  );
};

export default CoreTeam;
